<template>
    <div class="main-container">
        <!-- SECTION TITLE -->
        <section class="section-title">
            <div class="title-container">
                <div class="col-img">
                    <img src="./../assets/banner-exhibitor.png" alt="banner visitors" />
                </div>
                <div class="col-title">
                    <h1>Exhibitors</h1>
                    <p>Take this opportunity increase visibility and promote your solutions during this unique virtual event!</p>
                    <div class="actions-btn sponsors-btn margin-btn" @click="displayVideo('637368830')">Discover your Exhibitor Benefits</div>
                </div>
            </div>
            <exhibitors-list
                title="Current Exhibitors "
            >
            </exhibitors-list>
        </section>
        
        <!-- SECTION WHY EXHIBIT -->
        <section class="section-why">
            <div class="why-sep-top"></div>
            <h2>Benefits of Becoming an Exhibitor</h2>
            <div class="list-why-container">
                <ul class="list-why">
                    <li><strong>Promote</strong> your products and services </li>
                    <li><strong>Unveil</strong> your latest innovations</li>
                    <li><strong>Shine</strong> with an intuitive booth and customize it following your company identity</li>
                    <li><strong>Increase</strong> your company’s notoriety and visibility</li>
                    <li><strong>Surf</strong> on the trend of this innovative event</li>
                    <li><strong>Highlight</strong> our expertise through technical conferences and product demos</li>
                    <li><strong>Introduce</strong> your sales people and allow customers to make direct online appointments with them</li>
                    <li><strong>Make</strong> yourself known to distributors</li>
                    <li><strong>Search</strong> and identify your suppliers and subcontractors</li>
                    <li><strong>Get detailed</strong> statistics on the participants and visitors who visited your booth</li>
                </ul>
                <img src="./../assets/exhibit-bg.png" alt="phone" />
            </div>
            <div class="why-sep-bottom"></div>
        </section>

        <!-- SECTION WHO -->
        <section class="section-who">
            <h2>Domains Covered by the Conference</h2>
            <div class="who-container">
                 <div class="col-who">
                    <ul>
                        <li>AR/VR Solutions</li>
                        <li>AR/VR Device</li>
                        <li>Artificial Intelligence Experts and Vendors</li>
                        <li>Data Collection / Data Hub</li>
                        <li>IoT Plaform</li>
                        <li>IoT Applications</li>
                        <li>Machine Learning</li>
                        <li>Network / Connectivity</li>
                    </ul>
                </div>
                <div class="col-who">
                    <ul>
                        <li>Robotics</li>
                        <li>Sensors</li>
                        <li>Smart Tools</li>
                        <li>Consulting</li>
                        <li>Expertise</li>
                        <li>Integration</li>
                        <li>Support</li>
                        <li>Training</li>
                    </ul>
                </div>
            </div>
        </section>

        <!-- SECTION VISITS -->
        <section class="section-visits">
            <div class="why-sep-top"></div>
            <h2>Targeting Visitors for the Conference</h2>
            <div class="visits-container">
                <img src="./../assets/img-visits.png" alt="stand" />
                <ul class="list-visits">
                    <li>Consultant</li>
                    <li>Data Scientist</li>
                    <li>DSI</li>
                    <li>Engineer</li>
                    <li>Institution</li>
                    <li>Integrator</li>
                    <li>Investor</li>
                    <li>Local Collectivity</li>
                    <li>Manufacturer</li>
                    <li>Marketing</li>
                    <li>Procurement</li>
                    <li>R&D</li>
                    <li>Sales</li>
                    <li>Startups</li>
                    <li>Student</li>
                </ul>
            </div>
            <div class="why-sep-bottom"></div>
        </section>

        <!-- SECTION TARIFS -->
        <section class="section-tarifs">
            <h2>Booth Packages</h2>
            <!-- TABLEAUX DESK -->
            <div class="tarifs-tab">
                <div class="tarifs-header">
                    <div class="tarifs-col-main">Features / Booth category</div>
                    <div class="tarifs-col pro-col">BOOTH PACKAGE</div>
                </div>
                <div class="tarifs-tab-content">
                    <!-- row 1 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Main Colors & Logo customization of the 3D rendering of your booth</div>
                        <div class="tarifs-col"><img src="./../assets/puce-blue.png" alt="puce" /></div>
                    </div>
                    <!-- row 2 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Corporate Information & Contact Details</div>
                        <div class="tarifs-col"><img src="./../assets/puce-blue.png" alt="puce" /></div>
                    </div>
                    <!-- row 3 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Corporate Website Link</div>
                        <div class="tarifs-col"><img src="./../assets/puce-blue.png" alt="puce" /></div>
                    </div>
                    <!-- row 4 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Contact Form</div>
                        <div class="tarifs-col"><img src="./../assets/puce-blue.png" alt="puce" /></div>
                    </div>
                    <!-- row 5 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Quantity of Sales persons visible simultaneously on the booth for Chat & Video meetings</div>
                        <div class="tarifs-col"><p class="tarifs-number">4</p></div>
                    </div>
                    <!-- row 6 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Products / Services<span class="tarifs-title-light">Each product/service page includes:<br />
                            . Name<br />
                            . Short description<br />
                            . Pictures gallery (5 pictures max.)<br />
                            . PDF files (3 PDF files max.)<br />
                            . Videos links (3 videos max. stored on YouTube or Vimeo by the exhibitor)<br />
                            . External links (3 URLs max.)</span></div>
                        <div class="tarifs-col"><p class="tarifs-text">10</p></div>
                    </div>
                    <!-- row 7 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Sales Documentation (PDF only)<span class="tarifs-title-light"><i>(examples: Corporate brochure, products portfolio, invitations, etc.)</i></span></div>
                        <div class="tarifs-col"><p class="tarifs-text">5</p></div>
                    </div>
                    <!-- row 8 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Videos<span class="tarifs-title-light">(Stored on YouTube or Vimeo)<br /><i>(examples: Corporate videos, CEOs interviews, products portfolio, etc.)</i></span></div>
                        <div class="tarifs-col"><p class="tarifs-text">5</p></div>
                    </div>
                    <!-- row 9 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Specialized Documentation (PDF only)</div>
                        <div class="tarifs-col"><p class="tarifs-text">5</p></div>
                    </div>
                    <!-- row 10 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Technical Pitch<span class="tarifs-title-light"><i>(examples: sales pitch, live demo, product presentation)</i></span></div>
                        <div class="tarifs-col"><p class="tarifs-number">2</p></div>
                    </div>
                    <!-- row 11 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Database: List of all the attendees who visit your booth</div>
                        <div class="tarifs-col"><img src="./../assets/puce-blue.png" alt="puce" /></div>
                    </div>
                </div>
                <div class="tarifs-footer">
                    <div class="tarifs-col-main">PRICE</div>
                    <div class="tarifs-col pro-col">1490 € HT</div>
                </div>
            </div>
            
            <!-- BOX TARIFS RESP -->
  
            <div class="box-tarif box-pro">
                <div class="box-tarif-header">BOOTH PACKAGE</div>
                <div class="box-tarif-content">
                    <div class="box-tarif-price">1490 € HT</div>
                    <div class="box-tarif-features" v-if="pro">
                        
                        <!-- item 1 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Main Colors & Logo customization of the 3D rendering of your booth</h3>
                        </div>

                        <!-- item 2 -->
                        <div class="box-tarif-item"> 
                            <h3 class="box-tarif-item-title">Corporate Information & Contact Details</h3>
                        </div>

                        <!-- item 3 -->
                        <div class="box-tarif-item">    
                            <h3 class="box-tarif-item-title">Corporate Website Link</h3>
                        </div>

                        <!-- item 4 -->
                        <div class="box-tarif-item">    
                            <h3 class="box-tarif-item-title">Contact Form</h3>
                        </div>

                        <!-- item 5 -->
                        <div class="box-tarif-item">
                            <div class="box-tarif-main">
                                <div class="box-tarif-item-header">
                                    4
                                </div>
                                <h3 class="box-tarif-item-title">Sales persons visible simultaneously on the booth for Chat & Video meetings</h3>
                            </div>
                        </div>

                        <!-- item 6 -->
                        <div class="box-tarif-item">
                            <div class="box-tarif-main">
                                <div class="box-tarif-item-header">
                                    10 
                                </div>
                                <h3 class="box-tarif-item-title">Products / Services</h3>
                            </div>
                            <p class="box-tarif-item-text">
                                Each product/service page includes:<br />
                                <ul>
                                    <li>. Name</li>
                                    <li>. Short description</li>
                                    <li>. Pictures gallery (5 pictures max.)</li>
                                    <li>. PDF files (3 PDF files max.)</li>
                                    <li>. Videos links (3 videos max. stored on YouTube or Vimeo by the exhibitor)</li>
                                    <li>. External links (3 URLs max.)</li>
                                </ul>
                            </p>                            
                        </div>

                        <!-- item 7 -->
                        <div class="box-tarif-item">
                            <div class="box-tarif-main">
                                <div class="box-tarif-item-header">
                                    5
                                </div>
                                <h3 class="box-tarif-item-title">Sales Documentations (PDF only)</h3>
                            </div>
                            <p class="box-tarif-item-text">
                                <i>(examples: Corporate brochure, products portfolio, invitations, etc.)</i>
                            </p>                            
                        </div>

                        <!-- item 8 -->
                        <div class="box-tarif-item">
                            <div class="box-tarif-main">
                                <div class="box-tarif-item-header">
                                    5
                                </div>
                                <h3 class="box-tarif-item-title">Videos</h3>
                            </div>
                            <p class="box-tarif-item-text">
                                (Stored on YouTube or Vimeo)<br />
                                <i>(examples: Corporate videos, CEOs interviews, products portfolio, etc.)</i>
                            </p>                            
                        </div>

                        <!-- item 9 -->
                        <div class="box-tarif-item">
                            <div class="box-tarif-main">
                                <div class="box-tarif-item-header">
                                    5
                                </div>
                                <h3 class="box-tarif-item-title">Specialized Documentations (PDF only)</h3>
                            </div>
                                            
                        </div>

                        <!-- item 10 -->
                        <div class="box-tarif-item">
                            <div class="box-tarif-main">
                                <div class="box-tarif-item-header">
                                    2
                                </div>
                                <h3 class="box-tarif-item-title">Technical Pitch</h3>
                                <p class="box-tarif-item-text">
                                    <i>(examples: sales pitch, live demo, product presentation)</i>
                                </p>  
                            </div>
                        </div>

                        <!-- item 10 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Database: List of all the attendees who visit your booth</h3>
                        </div>

                        <div class="box-tarif-sep"></div>
                    </div>
                    <div class="box-tarif-btn" @click="pro = !pro">
                        <template v-if="!pro">
                            Show features <span class="box-tarif-btn-sub plus"></span>
                        </template>
                        <template v-else>
                            Hide features <span class="box-tarif-btn-sub moins"></span>
                        </template>
                    
                    </div>
                </div>
                    
            </div>
            
 

            <div class="tarifs-sep"></div>
            <h2>Add-ons</h2>
            <div class="tarifs-tab options-tab">
                <div class="tarifs-header options-tab">
                    <div class="tarifs-col-main">Additional options</div>
                    <div class="tarifs-col-price">Unit price</div>
                </div>
                <div class="tarifs-tab-content options-tab">
                    <!-- row 1 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Additional Sales Person</div>
                        <div class="tarifs-col-price"><p class="tarifs-text">150 € HT / unit</p></div>
                    </div>
                    <!-- row 2 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Additional Product or Service</div>
                        <div class="tarifs-col-price"><p class="tarifs-text">350 € HT / unit</p></div>
                    </div>
                    <!-- row 3 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Additional Sales Documentation</div>
                        <div class="tarifs-col-price"><p class="tarifs-text">50 € HT / unit</p></div>
                    </div>
                    <!-- row 4 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Additional Video</div>
                        <div class="tarifs-col-price"><p class="tarifs-text">50 € HT / unit</p></div>
                    </div>
                    <!-- row 5 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Additional Specialized Documentation</div>
                        <div class="tarifs-col-price"><p class="tarifs-text">50 € HT / unit</p></div>
                    </div>
                    <!-- row 6 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Additional Technical Pitch</div>
                        <div class="tarifs-col-price"><p class="tarifs-text">250 € HT / unit</p></div>
                    </div>
                    <!-- row 7 -->
                    <div class="tarifs-row">
                        <div class="tarifs-col-main">Let our technical team prepare your booth based on data and media provider by the exhibitor <span class="tarifs-title-light">(Data & media files provided by the exhibitor)</span></div>
                        <div class="tarifs-col-price"><p class="tarifs-text">750 € HT</p></div>
                    </div>
                </div>
            </div>
            <!-- FIN TABLEAUX DESK -->
            <!-- BOX TARIFS RESP -->
            <div class="box-tarif box-options">
                <div class="box-tarif-header">Additional options</div>
                <div class="box-tarif-content">
                    <div class="box-tarif-features" v-if="options">
                        
                        <!-- item 1 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Additional Sales Person</h3>
                            <div class="box-tarif-item-header">
                                150 € HT / unit
                            </div>
                        </div>

                        <!-- item 2 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Additional Product or Service</h3>
                            <div class="box-tarif-item-header">
                                350 € HT / unit
                            </div>
                        </div>

                        <!-- item 3 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Additional Sales Documentation</h3>
                            <div class="box-tarif-item-header">
                                50 € HT / unité / unit
                            </div>
                        </div>

                        <!-- item 4 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Additional Video</h3>
                            <div class="box-tarif-item-header">
                                50 € HT / unit
                            </div>
                            
                        </div>

                        <!-- item 5 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Additional Specialized Documentation</h3>
                            <div class="box-tarif-item-header">
                                50 € HT / unit
                            </div>
                            
                        </div>

                        <!-- item 6 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Additional Technical Pitch</h3>
                            <div class="box-tarif-item-header">
                                250 € HT / unit
                            </div>
                            
                        </div>

                        <!-- item 7 -->
                        <div class="box-tarif-item">
                            <h3 class="box-tarif-item-title">Let our technical team prepare your booth based on data and media provider by the exhibitor</h3>
                            <p>(Data & media files provided by the exhibitor)</p>
                            <div class="box-tarif-item-header">
                                750 € HT
                            </div>                            
                            
                        </div>

                        <div class="box-tarif-sep"></div>
                    </div>
                    <div class="box-tarif-btn" @click="options = !options">
                        <template v-if="!options">
                            Show options <span class="box-tarif-btn-sub plus"></span>
                        </template>
                        <template v-else>
                            Hide options<span class="box-tarif-btn-sub moins"></span>
                        </template>
                    
                    </div>
                </div>
                    
            </div>
            
            
        </section>

        <!-- SECTION CONTACT -->
        <section class="section-contact">
            <!-- <div class="contact-sep-top"></div> -->
            <h2>Contact Form<br /><span class="title-focus">to become an exhibitor</span> </h2>
            <ContactForm></ContactForm>
        </section>
    <video-modal 
        v-if="video"
        :videoID="videoID"
        v-on:close="closeVideo"
      >
      </video-modal>
    </div>
    
</template>

<script>
import ContactForm from './../components/ContactForm.vue';
import ExhibitorsList from "./../components/ExhibitorsList.vue";
import VideoModal from "./../components/VideoModal.vue";

export default {
    name : 'Exhibitors',
    components : {
        ContactForm,
        ExhibitorsList,
        VideoModal
    },
    data() {
        return {
            basic : false,
            pro : false,
            entreprise : false,
            options : false,
            video : false,
            videoID : null,
        }
    },
    methods : {
        displayVideo(id) {
        this.video = true;
        this.videoID = id;
        },
        closeVideo() {
        this.videoID = null;
        this.video = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    .section-title {
        margin-top:150px;
        padding:80px 0;
        background-image: url('./../assets/bg-home-participate.png');
        background-position: center right;
        background-repeat: no-repeat;
    }

    .title-container {
        width:$box;
        margin:0 auto;
        max-width: 90%;
        display: flex;
        align-items: center;
    }

    .col-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width:50%;
    }

    .col-img img {
        max-width: 90%;
    }

    .col-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
    }

    .col-title h1 {
        color:$main-color;
        font-size:40px;
        font-weight:700;
        padding: 0;
        margin: 0 0 20px 0;
        text-align: center;
    }

    .col-title p {
        color:$text-color;
        text-align: center;
        font-size:25px;
        line-height: 1.5em;
        max-width: 600px;
        margin: 0;
    }

    .section-why {
        padding:180px 0;
        position: relative;
        background-image: url('./../assets/bg-why-exhibit.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .why-sep-top {
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-top.png');
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .why-sep-bottom {
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-home-exhibit-bottom.png');
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .list-why-container {
        width:$box;
        margin:0 auto;
        max-width: 90%;
        display: flex;
        align-items: center;
    }

    .list-why-container img {
        margin-top: 40px;
    }

    .list-why {
        width: 66%;
        padding:0;
    }

    .list-why li {
        color:$secondary-color;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        font-weight:400;
        margin-right:20px;
    }

    .list-why li::before {
        content:'';
        width:25px;
        height:20px;
        background-image: url('./../assets/puce.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }

    .section-who {
        padding:80px 0 180px 0;
        background-image: url('./../assets/bg-home-participate.png');
        background-position: center right;
        background-repeat: no-repeat;
    }

    .section-who h2 {
        color:$main-color;
    }

    .who-container {
        width:$box;
        margin:80px auto 0 auto;
        max-width: 90%;
        display: flex;
    }

    .col-who {
        width:44%;
        margin:0 3%;
    }

    .col-who li {
        color:$main-color;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        font-weight:600;
        margin-right:20px;
    }

    .list-light {
        font-weight:400;
    }

    .col-who li::before {
        content:'';
        width:25px;
        height:22px;
        background-image: url('./../assets/puce-blue.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }

    .section-visits {
        padding:180px 0;
        background-color: $main-color;
        background-image: url('./../assets/bg-exhibitor-orange.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .section-visits h2 {
        color:#fff;
    }

    .visits-container {
        width:90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    .visits-container img {
        width:55%;
        margin-right:5%;
    }

    .visits-container .list-visits {
        width:40%;
    }

    .visits-container .list-visits li {
        color:#fff;
        font-size:20px;
        margin-bottom:20px;
        list-style: none;
        position: relative;
        left:  40px;
        font-weight:600;
        margin-right:20px;
    }

    .visits-container .list-visits li::before {
        content:'';
        width:25px;
        height:22px;
        background-image: url('./../assets/puce-white.png');
        background-position: center;
        background-size: initial;
        background-repeat: no-repeat;
        position: absolute;
        left:-40px;
        top:3px;
    }

    .section-tarifs {
        padding:80px 0;
    }

    .section-contact {
        padding:80px 0;
        background-image: url('./../assets/bg-contact.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }

    .contact-sep-top {
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:100px;
        background-image: url('./../assets/sep-contact-top.png');
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }



    /* TARIFS TAB */
    .tarifs-tab {
        width:1400px;
        max-width: 98%;
        margin: 40px auto;
        padding-top: 40px;
    }

    .tarifs-sep {
        height:80px;
        width: 100%;
    }

    .tarifs-tab.options-tab {
        width:800px;
        margin-top:-40px;
    }

    .tarifs-header, .tarifs-footer {
        display: flex;
    }

    .tarifs-footer {
        margin-top: -10px;
    }

    .tarifs-tab-content.options-tab {
        border-radius:10px;
    }

    .tarifs-tab-content.options-tab .tarifs-row:nth-child(1) {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .tarifs-tab-content.options-tab .tarifs-row:nth-child(7) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .tarifs-tab-content.options-tab .tarifs-col-main, .tarifs-header.options-tab .tarifs-col-main {
        width:80%
    } 

    .tarifs-header.options-tab .tarifs-col-price {
        color:$secondary-color;
        font-size:25px;
    }


    .tarifs-col-main {
        width:80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding:15px 20px;
    }

    .tarifs-col {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:15px 20px;
        min-width: 160px;
    }

    .tarifs-col-price {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:15px 20px;
    }

    .options-tab .tarifs-col-price {
        min-width: 180px;
    }

    .tarifs-header .tarifs-col-main {
        color:$secondary-color;
        font-size:25px;
    }

    .tarifs-footer .tarifs-col-main {
        color:$secondary-color;
        font-size:25px;
        align-items: flex-end;
    }


    .basic-col {
        background:#686767;
        color:#fff;
        font-size:22px;
        font-weight: 700;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        letter-spacing: 1px;
    }

    .tarifs-footer .basic-col, .tarifs-footer .pro-col, .tarifs-footer .entreprise-col {
        padding:30px 20px 20px 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .pro-col {
        background:$secondary-color;
        color:#fff;
        font-size:22px;
        font-weight: 700;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        letter-spacing: 1px;
    }
    

    .entreprise-col {
        background:$main-color;
        color:#fff;
        font-size:22px;
        font-weight: 700;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        letter-spacing: 1px;
    }

    .tarifs-tab-content {
        border-radius:10px;
        position: relative;
        z-index: 2;
        border-top-right-radius: 0;
        box-shadow: 2px 2px 14px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 2px 2px 14px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 2px 2px 14px 0px rgba(0,0,0,0.75);
    }

    .tarifs-row {
        display: flex;
        min-height: 60px;
        background: #fff;
    }

    .tarifs-tab-content .tarifs-row:nth-child(even) {
        background:#f3f3f3
    }

    .tarifs-tab-content .tarifs-row:nth-child(1) {
        border-top-left-radius: 10px;
    }

    .tarifs-tab-content .tarifs-row:nth-child(11) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .tarifs-row .tarifs-col-main {
        color:$text-color;
        text-align: left;
        font-weight: 600;
        align-items: flex-start;
        line-height: 1.3em;
    }

    .tarifs-number {
        margin:0;
        padding: 0;
        font-size:22px;
        font-weight: 700;
        color: $secondary-color;
    }

    .tarifs-text {
        margin:0;
        padding: 0;
        font-size:22px;
        font-weight: 700;
        color: $secondary-color;
    }

    .tarifs-title-light {
        margin:0;
        padding: 0;
        font-weight: 400;
    }

    .tarifs-col img {
        width:20px;
    }

    /* TARIFS BOX */

    .box-tarif {
        display:none;
        width:400px;
        max-width: 90%;
        margin:40px auto;
        border-radius:10px;
        background: #eee;
        padding-bottom: 20px;
    }


    .box-tarif-header {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size:20px;
        text-align: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-transform: uppercase;
    }

    .box-basic .box-tarif-header {
        background:#686767;
        color:#fff;
    }

    .box-options .box-tarif-header {
        color:$secondary-color;
        background: #e2e2e2;
    }

    .box-pro .box-tarif-header {
        color:#fff;
        background: $secondary-color;
    }

    .box-entreprise .box-tarif-header {
        color:#fff;
        background: $main-color;
    }

    .box-tarif-price {
        padding:20px;
        font-size:25px;
        font-weight: 700;
        color: $secondary-color;
        text-align: center;
    }

    .box-basic .box-tarif-price {
        color:#686767;
    }

    .box-entreprise .box-tarif-price {
        color:$main-color;
    }

    .box-tarif-btn {
        margin-top:20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:$secondary-color;
        cursor: pointer;
    }

    .box-tarif-btn-sub {
        width:20px;
        height:20px;
        margin-left:10px;
    }

    .box-tarif-btn-sub.plus {
        background-image: url('./../assets/icon-plus.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .box-tarif-btn-sub.moins {
        background-image: url('./../assets/icon-moins.png');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .box-tarif-features {
        width:90%;
        margin:0 auto;
    }

    .box-tarif-sep {
        height:1px;
        background:#6b6b6b;
        width: 60%;
        margin: 0 auto;
    }

    .box-tarif-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:30px 0;
    }

    .box-tarif-item-header {
        font-size:20px;
        font-weight: 700;
        margin:0;
        color:$secondary-color;
        display: inline;
    }


    .box-tarif-item h3 {
        font-size:15px;
        margin:0;
        font-weight: 600;
        color:$text-color;
        line-height: 20px;
        margin-top:4px;
        display: inline;
        text-align: center;
    }

    .box-tarif-item p {
        text-align: center;
        font-size:15px;
        margin:0;
        font-weight: 400;
        color:$text-color;
    }

    .box-tarif-item ul {
        margin:0 20px;
        list-style: none;
        padding:0;
    }

    .box-tarif-item ul li {
        text-align: left;
    }

    .box-tarif-main {
        text-align: center;
    }



    @media screen and (max-width:900px) {
        .tarifs-tab {
            display: none;
        }

        .box-tarif {
            display:block;
        }

    }

    @media screen and (max-width:768px) {

        .section-tarifs, .section-contact, .section-who {
            padding: 40px 0;
        }

        .col-title h1 {
            font-size:30px
        }   
        
        .col-title p {
            font-size:20px;
        }

        .title-container {
            flex-direction: column;
        }

        .col-img, .col-title {
            width:96%;
        }

        .section-title {
            padding:40px 0;
        }

        .list-why li, .col-who li, .visits-container .list-visits li {
            font-size:18px;
            left:20px;
        }

        .who-container, .visits-container {
            flex-direction: column;
        }

        .list-why-container {
            flex-direction: column-reverse;
        }

        .col-who {
            width:100%;
        }

        .visits-container img {
            width:80%;
            margin-bottom: 40px;
            margin-top:40px;
        }

        .visits-container .list-visits {
            width:100%;
        }

        .list-why {
            width:90%;
        }

        .list-why-container img {
            margin:40px auto;
            width:80%;
            max-width: 400px;
        }

        
    }
</style>